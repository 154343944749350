import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { PageLayout } from '@hse-design/react';
import { ComponentLinks } from '../common';
import { Link } from '../common/Link.jsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "pagelayout"
    }}>{`PageLayout`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { PageLayout } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks storybook={'/?path=/story/layout-pagelayout--playground'} vue={'/components/HsePageLayout/HsePageLayout.html'} vueStorybook={'/?path=/story/layout-pagelayout--playground'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`PageLayout – компонент для быстрого создания лейаута приложения. У PageLayout есть несколько вариантов отображения, `}<a parentName="p" {...{
        "href": "#%D0%B2%D0%B0%D1%80%D0%B8%D0%B0%D0%BD%D1%82%D1%8B"
      }}>{`подробнее в примерах ниже`}</a>{`.`}</p>
    <p>{`Для передачи содержимого в сайдбар и тапбар используйте пропы `}<inlineCode parentName="p">{`sidebarItems`}</inlineCode>{` и `}<inlineCode parentName="p">{`tapbarItems`}</inlineCode>{`. Данные пропы принимают массив с объектами, реализующих интерфейс `}<inlineCode parentName="p">{`PageLayoutNavigationItem[]| ((breakpoint: Breakpoint) => PageLayoutNavigationItem[]`}</inlineCode>{`: вы можете передать массив айтемов, либо функцию, возвращающую массив (первым параметром функции будет название текущего брейкпоинта). Каждому объекту можно добавить лейбл, иконку и бэдж (для сайдбара) с помощью свойств `}<inlineCode parentName="p">{`label`}</inlineCode>{`, `}<inlineCode parentName="p">{`icon`}</inlineCode>{`, `}<inlineCode parentName="p">{`badge`}</inlineCode></p>
    <p>{`Для задания выбранного айтема обрабатывайте события `}<inlineCode parentName="p">{`onSidebarItemClick`}</inlineCode>{` и `}<inlineCode parentName="p">{`onTapbarItemClick`}</inlineCode>{`, передайте в пропы `}<inlineCode parentName="p">{`sidebarSelectedItem`}</inlineCode>{`, `}<inlineCode parentName="p">{`tapbarSelectedItem`}</inlineCode>{` выбранный айтем.`}</p>
    <p>{`Для передачи произвольных пропов хедеру, сайдбару или тапбару используйте пропы `}<inlineCode parentName="p">{`headerProps`}</inlineCode>{`, `}<inlineCode parentName="p">{`sidebarProps`}</inlineCode>{`, `}<inlineCode parentName="p">{`tapbarProps`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "варианты"
    }}>{`Варианты`}</h3>
    <p>{`У PageLayout 4 варианта отображения:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`first`}</inlineCode>{` - с хедером на всех разрешениях, на брейкпоинте `}<inlineCode parentName="p">{`tablet-large`}</inlineCode>{` ниже добавляется сайдбар`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`second`}</inlineCode>{` - с хедером и сайдбаром на всех разрешениях`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`third`}</inlineCode>{` - с хедером на брейкпоинте `}<inlineCode parentName="p">{`desktop-small`}</inlineCode>{` и выше, сайдбаром и тапбаром на брейкпоинте `}<inlineCode parentName="p">{`tablet-large`}</inlineCode>{` и ниже`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`fourth`}</inlineCode>{` - с хедером и сайдбаром на брейкпоинте `}<inlineCode parentName="p">{`desktop-small`}</inlineCode>{` и выше, сайдбаром и тапбаром на брейкпоинте `}<inlineCode parentName="p">{`tablet-large`}</inlineCode>{` и ниже`}</p>
      </li>
    </ul>
    <p>Варианты отображения можно увидеть в <Link subdomain="storybook" href={'/?path=/story/layout-pagelayout--playground'} mdxType="Link">Storybook</Link></p>
    <h3 {...{
      "id": "вариант-сайдбара"
    }}>{`Вариант сайдбара`}</h3>
    <p>{`Сайдбару можно задать вариант с помощью пропа `}<inlineCode parentName="p">{`sidebarVariant`}</inlineCode>{`. Сетка контента изменится в соответствии с вариантом сайдбара`}</p>
    <p>Разные варианты сайдбара можно увидеть в <Link subdomain="storybook" href={'/?path=/story/layout-pagelayout--playground'} mdxType="Link">Storybook</Link></p>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={PageLayout} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      